:root {
  --mainColor: #17446e;
  --secondaryColor: #40a9ff;
  --disabledColor: #c0c0c0;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --lightGrey: #f5f5f5;
  --red: red;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
